import React from "react";
import { Trans, useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";

import Card from "../../components/Card";
import { Container } from "../../components/Container";
import { SendReportButton } from "../../components/SendReport/SendReportButton";
import { getTranslationList } from "../../utils/common";

const styles = css`
  article {
    ${tw`text-center items-center max-w-5xl`}
  }
  p,
  ul {
    ${tw`mx-auto`}
  }
  ul {
    ${tw`sm:mx-32 sm:(text-xl)`}
  }
  svg {
    ${tw`sm:mt-0`}
  }
  h2 {
    ${tw`max-w-4xl mx-auto`}
  }
`;

const SuccessfulBlueprint: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();

  const successfulBlueprintList = getTranslationList(t, "optIn.successfulBlueprint.list");

  return (
    <Container>
      <section css={styles}>
        <article {...rest}>
          <h2>{t("optIn.successfulBlueprint.header")}</h2>
          <div tw="grid grid-cols-1 sm:grid-cols-3 mt-10">
            {successfulBlueprintList.map((item, i) => (
              <Card header={i + 1} key={i}>
                <Trans i18nKey={t(item)} components={{ bold: <strong />, br: <br /> }} />
              </Card>
            ))}
          </div>
          <SendReportButton tw="mx-auto mt-8" />
        </article>
      </section>
    </Container>
  );
};
export default SuccessfulBlueprint;
