import humps from "humps";
import React, { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ContactForm } from "../../interfaces/contact_form";
import { Api } from "../../services/api/Api";
import { useContactAPIUrl } from "../../utils/common";
import { ContactUsForm } from "../ContactUsForm";
import Modal from "../Modal";

interface Props {
  useModal: any;
}

const initForm: ContactForm = {
  firstName: "",
  lastName: "",
  email: "",
  message: "",
  contactReason: "3",
  captcha: "",
  captchaInput: "",
  agreement: false,
  lang: "en",
  phoneNumber: ""
};

const SendReportPopup: React.FC<Props> = ({ useModal, ...rest }) => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const [form, setForm] = useState<ContactForm>(initForm);
  const [hasError, setHasError] = useState<boolean>(false);
  const [successful, setSuccessful] = useState<boolean>(false);
  const api = new Api();
  const [srcUrl, setSrcUrl] = useState<string | null>(null);

  const defaultPdf = "Seven-Little-Known-Ways-To-Skyrocket-Your-Production-By-135-Percent.pdf";
  const docPrefix = "/docs/";
  const pdfLink = docPrefix + defaultPdf;
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    form.lang = i18n.language;
    const request = new Request(useContactAPIUrl(), {
      method: "POST",
      body: JSON.stringify({ data: { type: "contacts", attributes: humps.decamelizeKeys(form) } }),
      headers: { "Content-Type": "application/vnd.api+json" }
    });

    fetch(request)
      .then(response => {
        if (!response.ok) {
          setSuccessful(false);
          throw Error(response.statusText);
        } else if (response.ok) {
          setHasError(false);
          setSuccessful(true);
        }
      })
      .then(() => setForm(initForm))
      .catch(() => setHasError(true));
  };

  const status = { error: hasError, success: successful };
  async function getLink() {
    const hvcos = await api.hvco.find();
    if (hvcos.data.length > 0) {
      const hvcoFiles = hvcos.data[0]?.hFiles;
      let latestFile = hvcoFiles[0]?.originalUrl;
      hvcoFiles.forEach(file => {
        if (file.language === language) {
          latestFile = file.originalUrl;
        }
      });
      if (latestFile !== undefined) {
        setSrcUrl(latestFile);
      }
    } else {
      setSrcUrl(pdfLink);
    }
  }

  useEffect(() => {
    // This code will run only once during the initial render
    getLink();
  }, []);

  return (
    <Modal usedModal={useModal} height="95%" overflowY="auto" maxHeight="95vh" {...rest}>
      <ContactUsForm form={form} setForm={setForm} handleSubmit={handleSubmit} status={status} freeReport={true} freeReportUrl={srcUrl} />
    </Modal>
  );
};

export default SendReportPopup;
