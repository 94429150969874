import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import tw, { css } from "twin.macro";

import Button from "../Button";
import { useModal } from "../Modal";
import SendReportPopup from "./SendReportPopup";

interface Props {
  label: string;
  isOutlined: boolean;
  isBig: boolean;
  commentFirst?: boolean;
  skipComment?: boolean;
}

const commentStyle = css`
  ${tw`mx-auto my-6 max-w-prose`}
  h3 {
    ${tw`text-xl sm:text-2xl`}
  }
  p {
    ${tw`text-sm sm:text-base`}
  }
`;

export const SendReportButton: React.FC<Partial<Props>> = ({ label, isOutlined, isBig, commentFirst, skipComment, ...rest }) => {
  const { t } = useTranslation();
  const sendReportModal = useModal();

  const comment = (
    <div css={commentStyle} {...rest}>
      <h3>
        <strong>{t("optIn.sendReport.header")}</strong>
      </h3>
      <p>{t("optIn.sendReport.comment")}</p>
    </div>
  );

  return (
    <div tw="text-center" {...rest}>
      {commentFirst && !skipComment && comment}
      <Button
        tw="text-base sm:text-xl"
        isBig={isBig}
        isSecondary={!isOutlined}
        isOutlined={isOutlined}
        onClick={() => sendReportModal.open()}
      >
        {t(label || "optIn.sendReport.sendReport")}
      </Button>
      {!commentFirst && !skipComment && comment}

      <SendReportPopup useModal={sendReportModal} />
    </div>
  );
};
